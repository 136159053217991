import { clsx } from 'clsx';
import {
  ComponentPropsWithRef,
  ReactNode,
  useId,
  useRef,
  useState,
} from 'react';
import { useComposedRefs } from '../utils/use-composed-refs.ts';
import {
  input,
  inputCharacterCount,
  inputContainer,
  inputContainerWithAfter,
  inputContainerWithBefore,
  inputError,
  inputLabel,
  inputOptional,
  inputVariants,
} from './input.css.ts';

export interface InputProps extends ComponentPropsWithRef<'input'> {
  errorMessage?: string;
  before?: ReactNode;
  after?: ReactNode;
  label?: string;
  inputClassName?: string;
  containerClassName?: string;
  markOptional?: boolean;
}

export const Input = ({
  className,
  inputClassName,
  errorMessage,
  maxLength,
  value,
  defaultValue,
  before,
  after,
  onChange,
  label,
  id,
  containerClassName,
  markOptional,
  ref,
  ...rest
}: InputProps) => {
  const reactId = useId();
  const inputId = id || reactId;

  const inputRef = useRef<HTMLInputElement>(null);

  const setInputRef = useComposedRefs(ref, inputRef);

  const [count, setCount] = useState<number>(
    ((value || defaultValue) as string)?.length || 0,
  );

  const trackCharacterCount: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setCount(e.target.value.length);
  };

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={inputId} className={inputLabel}>
          {label}
          {markOptional && <span className={inputOptional}> optional</span>}
        </label>
      )}
      <div
        className={clsx(
          inputContainer,
          inputVariants[errorMessage ? 'error' : 'default'],
          before && inputContainerWithBefore,
          (maxLength || after) && inputContainerWithAfter,
          className,
        )}
        data-has-value={count > 0 ? '' : undefined}
        data-disabled={rest.disabled ? '' : undefined}
      >
        {before}
        <input
          type="text"
          ref={setInputRef}
          id={inputId}
          className={clsx(input, inputClassName)}
          value={value}
          maxLength={maxLength}
          onChange={(e) => {
            trackCharacterCount(e);
            onChange?.(e);
          }}
          {...rest}
        />
        {maxLength ? (
          <span className={inputCharacterCount}>
            {count}/{maxLength}
          </span>
        ) : null}
        {after}
      </div>
      {errorMessage && <p className={inputError}>{errorMessage}</p>}
    </div>
  );
};
